const FIELDS_MAPPING = [
  {
    fieldName: 'issueState'
    // Use apiParamName to specify the API param name in case fieldNmae is different.
    // apiParamName: 'issueStateOtherName'
  },
  {
    fieldName: 'premiumAllocation'
  },
  {
    fieldName: 'strategies'
  },
  {
    fieldName: 'taxStatus'
  },
  {
    fieldName: 'ownerType'
  },
  {
    fieldName: 'entityName',
    defaultValue: undefined
  },
  {
    fieldName: 'isSecondPerson',
    defaultValue: false
  },
  {
    fieldName: 'firstName'
  },
  {
    fieldName: 'lastName'
  },
  {
    fieldName: 'age'
  },
  {
    fieldName: 'gender'
  },
  {
    fieldName: 'isOwner'
  },
  {
    fieldName: 'isAnnuitant'
  },
  {
    fieldName: 'isCoveredPerson'
  },
  {
    fieldName: 'secondFirstName',
    defaultValue: undefined
  },
  {
    fieldName: 'secondLastName',
    defaultValue: undefined
  },
  {
    fieldName: 'secondAge',
    defaultValue: undefined
  },
  {
    fieldName: 'secondGender',
    defaultValue: undefined
  },
  {
    fieldName: 'isCoOwner',
    defaultValue: false
  },
  {
    fieldName: 'isCoAnnuitant',
    defaultValue: false
  },
  {
    fieldName: 'isSecondCoveredPerson',
    defaultValue: false
  },
  {
    fieldName: 'incomeBenefitStartAge',
    defaultValue: false
  },
  {
    fieldName: 'secondIncomeBenefitStartAge',
    defaultValue: false
  },
  {
    fieldName: 'hasWithdrawals'
  },
  {
    fieldName: 'partialWithdrawals',
    apiParamName: 'withdrawals'
  },
  {
    fieldName: 'requiredMinDistributionOption',
    defaultValue: false
  },
  {
    fieldName: 'minimumDistributionStartAge',
    defaultValue: undefined
  },
  {
    fieldName: 'enhacedIncomeOption',
    defaultValue: false
  },
  {
    fieldName: 'enhacedIncomeStartAge',
    defaultValue: false
  },
  {
    fieldName: 'email',
    defaultValue: undefined
  },
  {
    fieldName: 'firmName',
    defaultValue: undefined
  },
  {
    fieldName: 'preparedBy',
    defaultValue: undefined
  },
  {
    fieldName: 'phoneNumber',
    defaultValue: undefined
  },
  {
    fieldName: 'additionalIllustMethod',
    defaultValue: false
  },
  {
    fieldName: 'illustrationMethod',
    defaultValue: undefined
  },
  {
    fieldName: 'variableAssumedRate',
    defaultValue: undefined
  },
  {
    fieldName: 'overrideFixed',
    defaultValue: false
  },
  {
    fieldName: 'rateIllustration',
    defaultValue: undefined
  },
  {
    fieldName: 'assumedConstantRate',
    defaultValue: 0.03
  },
  {
    fieldName: 'baseIllustration',
    defaultValue: false
  },
  {
    fieldName: 'monteCarlo',
    defaultValue: false
  },
  {
    fieldName: 'incomePlanning',
    defaultValue: false
  },
  {
    fieldName: 'additionalBenefits',
    defaultValue: false
  },
  {
    fieldName: 'x5Crediting',
    defaultValue: false
  },
  {
    fieldName: 'brochure',
    defaultValue: false
  },
  {
    fieldName: 'rateSheet',
    defaultValue: false
  },
  {
    fieldName: 'morganStanleyEx',
    defaultValue: false
  },
  {
    fieldName: 'pimco',
    defaultValue: false
  },
  {
    fieldName: 'incomeOption',
    defaultValue: false
  },
  {
    fieldName: 'withdrawalsValueType',
    defaultValue: 'amount'
  },
  {
    fieldName: 'rmdLifeType',
    defaultValue: 'Uniform'
  },
  {
    fieldName: 'product'
  },
  {
    fieldName: 'showAnnualIncreasingIncome'
  }
];

export default FIELDS_MAPPING;
