import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import qs from 'query-string';
import { inIframe } from '../utils/utils';

const TOKEN_NAME = 'AIG_X5_TOKEN';

class Auth {
  constructor({ SSO_URL }) {
    this.authUrl = SSO_URL;
  }

  isAuthenticated = () => {
    const token = this.getToken();
    return token && !this.isTokenExpired(token);
  };

  isTokenExpired = token => {
    try {
      const { exp } = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return exp < currentTime;
    } catch (err) {
      return true;
    }
  };

  getDataLayer = token => jwtDecode(token);

  setToken = token => {
    if (token) {
      sessionStorage.setItem(TOKEN_NAME, token);
    }
  };

  getToken = () => {
    let token;

    try {
      const parsedURL = qs.parse(window.location.search);
      token = parsedURL.token || null;
    } catch (error) {
      const params = new URLSearchParams(window.location.search);
      token = params.get('token') || null;
    }

    this.setToken(token);

    const jwtToken = token || sessionStorage.getItem(TOKEN_NAME);

    if (jwtToken === 'null' || _.isEmpty(jwtToken)) {
      return null;
    }

    return jwtToken;
  };

  logout = () => {
    sessionStorage.removeItem(TOKEN_NAME);
  };

  redirectToLogin() {
    const currentPath = window.location.href;
    window.location.href = `${this.authUrl}${currentPath}`;
  }

  tryTriggerRefresh = () => {
    if (inIframe()) {
      window.parent.postMessage('Get new access token', '*');
    }
  };
}

export default Auth;
