export const MAX_STRATEGIES_ALLOWED = 3;
export const MAX_PARTIAL_WITHDRAWALS_ALLOWED = {
  BASIC: 3,
  COMMON: 9,
  ADVANCE: 10
};
export const MAX_PARTIAL_WITHDRAWALS_ALLOWED_ADVANCE_STATES = ['CA'];
export const MAX_PARTIAL_WITHDRAWALS_ALLOWED_BASIC = 3;
export const NOTIFICATION_SUCCESS_TYPE = 'Success';
export const NOTIFICATION_ERROR_TYPE = 'Error';
export const TAX_STATUS_QUALIFIED = 'Qualified';
export const TAX_STATUS_NON_QUALIFIED = 'Non-Qualified';
export const OWNER_TYPE_ENTITY = 'entity';
export const OWNER_TYPE_PERSON = 'person';
export const ILLUSTRATION_METHOD_VARIABLE_ASSUMED = 'variableAssumed';
export const ILLUSTRATION_METHOD_LEVEL_ASSUMED = 'levelAssumed';
export const MIN_PERSON_AGE = 40;
export const MAX_PERSON_AGE = 75;
export const MIN_PERSON_INCOME_AGE = 50;
export const MAX_PERSON_INCOME_AGE = 89;
export const MIN_YEARS_OF_DEFERRAL = 10;
export const MAX_YEARS_OF_DEFERRAL = 49;
export const MAX_YEARS_OF_DEFERRAL_ACCELERATOR = 20;
export const ADDITIONAL_ILLUSTRATION_FIXED_RATE = '3.00';
export const APP_CDN_URL = process.env.REACT_APP_CDN_URL;
export const NAIC_AWS_CDN_URL = process.env.REACT_APP_NAIC_AWS_CDN_URL;
export const NAIC_ACC_AWS_CDN_URL = process.env.REACT_APP_NAIC_ACC_AWS_CDN_URL;
export const APP_API_URL = process.env.REACT_APP_API_URL;
export const STEPS = {
  CASE: 1
};
export const AMOUNT = 'amount';
export const PERCENTAGE = 'percentage';
export const MAX_WITHDRAWALS_COTRACT_YEAR = 11;
export const MAX_WITHDRAWALS_COTRACT_YEAR_PERCENTAGE = 0.1;
export const MAX_ILLUSTRATION_YEARS_NO_INCOME = 55;
export const MAX_ILLUSTRATION_AGE_NO_INCOME = 95;
export const MAX_WITHDRAWAL_PERCENTAGE = 10;

export const Advantage = 'Advantage';
export const Accelerator = 'Accelerator';

export const Strategies = {
  One: '1Y PTP',
  Five: 'X5-5x1',
  Ten: 'X5-10x2'
};

export const RMD_LIFE_TYPE = {
  JOINT: 'Joint',
  UNIFORM: 'Uniform'
};
