import { TAX_STATUS_NON_QUALIFIED, AMOUNT } from '../../../constants';

const startValues = {
  // Case
  issueState: undefined,
  premiumAllocation: '',
  product: '',
  strategies: [
    {
      strategyName: undefined,
      index: undefined,
      premiumAllocation: 0,
      percentage: 100
    }
  ],
  // Profile
  taxStatus: TAX_STATUS_NON_QUALIFIED,
  ownerType: 'person',
  entityName: '',
  owner: {
    firstName: '',
    lastName: '',
    age: undefined,
    gender: undefined,
    owner: true,
    annuitant: true,
    coveredPerson: true
  },
  isSecondPerson: false,
  secondPerson: {
    firstName: '',
    lastName: '',
    age: undefined,
    gender: undefined,
    jointOwner: false,
    coAnnuitant: false,
    coveredPerson: false
  },
  // Income
  incomeBenefit: {
    startAge: undefined,
    secondStartAge: undefined
  },
  isEnhancedIncomeState: false,
  hasWithdrawals: false,
  enhacedIncomeOption: false,
  rmdLifeType: 'Uniform',
  requiredMinDistributionOption: false,
  minimumDistributionStartAge: undefined,
  partialWithdrawals: [],
  withdrawalsValueType: AMOUNT,
  enhacedIncome: { startAge: '', secondStartAge: '' },
  incomeOption: true,
  showAnnualIncreasingIncome: false,
  // Output
  preparedBy: '',
  email: '',
  phoneNumber: '',
  firmName: '',
  additionalIllustMethod: false,
  illustrationMethod: 'variableAssumed',
  variableAssumedRate: 'Assumed Scenario - Moderate',
  fixedRate: '3', // TODO: Confirm if this can be a number
  overrideFixed: false,
  rateIllustration: '3.00',
  assumedConstantRate: 0.03,
  // Outputs
  baseIllustration: true,
  monteCarlo: true,
  incomePlanning: false,
  additionalBenefits: false,
  x5Crediting: false,
  brochure: false,
  rateSheet: false,
  morganStanleyEx: false,
  pimco: false,
  isCustomeIllustrationPrepopulationAction: false
};

export default startValues;
