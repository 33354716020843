import axios from 'axios';

import get from 'lodash/get';

import RefreshTokenHelper from './RefreshTokenHelper';

class Api {
  constructor({ auth, baseURL }) {
    this.auth = auth;
    this.refreshTokenHelper = new RefreshTokenHelper({
      onTimeout: this.onRefreshTimeout.bind(this)
    });
    axios.defaults.baseURL = baseURL;
    axios.interceptors.request.use(
      this.onRequest.bind(this),
      this.onRequestError.bind(this)
    );
    axios.interceptors.response.use(
      this.onResponse.bind(this),
      this.onResponseError.bind(this)
    );
  }

  onRefreshTimeout = () => {
    this.auth.redirectToLogin();
  };

  onRequestError = error => Promise.reject(error);

  onResponseError = error => {
    const config = error.config || {};
    const status = get(error, 'response.status', null);

    if (status === 401) {
      if (!this.refreshTokenHelper.isRefreshing) {
        this.auth.logout();
        this.refreshTokenHelper.refresh(this.auth.authUrl);
      }
      return new Promise(resolve => {
        this.refreshTokenHelper.addListener(() => {
          const refreshToken = this.auth.getToken();
          config.headers.Authorization = `Bearer ${refreshToken}`;
          config.withCredentials = true;
          config.baseURL = undefined;
          resolve(axios.request(config));
        });
      });
    }
    return Promise.reject(error);
  };

  onRequest = config => {
    const token = this.auth.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.withCredentials = true;
    }

    return config;
  };

  onResponse = response => response;
}

export default Api;
