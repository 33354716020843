import React, { useLayoutEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import Auth from '../api/Auth';
import Api from '../api/Api';
import { removeParamFromURL } from '../utils/utils';

const AuthContext = React.createContext();
const auth = new Auth({ SSO_URL: process.env.REACT_APP_SAML_SSO_URL });

// Initialize Api and bind token
const api = new Api({
  auth,
  baseURL: process.env.REACT_APP_API_URL
});

function AuthProvider(props) {
  const isAuthenticated = auth.isAuthenticated();
  const [user, setUser] = useState(null);
  const [appIsReady, setAppIsReady] = useState(false);

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      auth.redirectToLogin();
      return;
    }
    const token = auth.getToken();
    const userData = auth.getDataLayer(token);
    setUser(userData);
    setAppIsReady(true);
    const { anxCode, fieldOdsKey, fieldSalesforceId } = userData;
    // Google tag manager
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
      dataLayer: {
        field_code: anxCode,
        field_ods_key: fieldOdsKey,
        field_salesforce_id: fieldSalesforceId
      }
    };

    TagManager.initialize(tagManagerArgs);

    auth.tryTriggerRefresh();

    window.history.replaceState(
      null,
      null,
      removeParamFromURL('token', window.location.href)
    );
  }, [isAuthenticated]);

  // make a login request
  const login = () => {
    auth.tryTriggerRefresh();
  };
  // clear the token in localStorage and the user data
  const logout = () => {
    auth.logout();
  };

  if (!isAuthenticated) {
    return <div />;
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, login, logout, api, appIsReady }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}
export { AuthProvider, useAuth };
