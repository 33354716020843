import { removeEvent, bindEvent } from '../utils/utils';

class RefreshTokenHelper {
  constructor({ onTimeout }) {
    this.id = 'refreshTokenIFrame';
    this.isRefreshing = false;
    this.timeout = null;
    this.listeners = [];
    this.onTimeout = onTimeout;
    this.onMessageReceived = this.onMessageReceived.bind(this);
  }

  onMessageReceived() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.isRefreshing = false;

    this.callListeners();

    this.removeIFrame();
  }

  refresh(authUrl) {
    this.isRefreshing = true;
    this.createIFrame(authUrl);
    this.startTimeout();
  }

  callListeners() {
    this.listeners.forEach(cb => cb());
    this.listeners = [];
  }

  removeIFrame() {
    const iframeElement = document.getElementById(this.id);
    if (document.body.contains(iframeElement)) {
      iframeElement.parentElement.removeChild(iframeElement);
    }
    removeEvent(window, 'message', this.onMessageReceived);
  }

  createIFrame(url) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', url);
    iframe.setAttribute('id', this.id);
    iframe.style.display = 'none';
    bindEvent(window, 'message', this.onMessageReceived);
    document.body.appendChild(iframe);
  }

  startTimeout() {
    this.timeout = setTimeout(() => {
      if (this.isRefreshing && this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
        this.isRefreshing = false;
        if (this.onTimeout) {
          this.onTimeout();
        }
      }
    }, 15000);
  }

  addListener(callback) {
    this.listeners.push(callback);
  }
}

export default RefreshTokenHelper;
